:local(.inner) {
  max-width: 1000px;
  margin: 0 auto;
}

:local(.section) {
  padding: var(--spacing4x) 0;
  color: #232;
  @media (max-width: 640px) {
    padding: var(--spacing);
  }
}

:local(.sectionHero) {
  padding: var(--spacing8x);
  line-height: 180%;
  :local(.sectionTitleMark) {
    font-size: 48px;
    font-weight: 500;
    letter-spacing: -0.05em;
    line-height: 180%;
    text-align: center;
    color: var(--mainColor);
  }
  :local(.description) {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.025em;
    text-align: center;
    margin-top: var(--spacing2x);
    color: #444;
  }
  background: #fff;

  @media (max-width: 640px) {
    padding: var(--spacing2x);
    :local(.sectionTitleMark) {
      font-size: 24px;
    }
  }
}

:local(.sectionTitle) {
  display: block;
  text-align: center;
  margin-bottom: var(--spacing2x);
  letter-spacing: -0.05em;
  text-align: center;
  font-size: 32px;
  font-weight: 500;
}

:local(.sectionSubtitle) {
  margin: var(--spacing4x) 0;
  letter-spacing: -0.05em;
  font-size: 30px;
  text-align: center;

  @media (max-width: 640px) {
    margin: var(--spacing2x) 0;
    font-size: 20px;
    padding: 0 var(--spacing);
  }
}

:local(.sectionProduct) {
  background: #efe;
  border-bottom: 4px solid var(--mainColor);
  color: #232;
}
:local(.product) {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "left right";
  :local(.image) {
    width: 200px;
    height: 400px;
    img {
      width: 100%;
      margin: 0 100px;
    }
  }
  :local(.withImageLeft .image) {
    grid-area: left;
  }
  :local(.withImageLeft .info) {
    grid-area: right;
  }
  :local(.info) {
    line-height: 200%;
    :local(.title) {
      font-size: 24px;
      font-weight: 500;
      margin-bottom: var(--spacing);
    }
    :local(.details) {
      font-size: 14px;
    }
    :local(.original_price) {
      font-size: 18px;
      font-weight: normal;
      color: #444;
      text-decoration: line-through;
      text-decoration-color: var(--mainColor);
      text-decoration-thickness: 2px;
      margin: var(--spacing2x) var(--spacing_half) 0;
    }
    :local(.v) {
      display: inline-block;
      vertical-align: middle;
      font-weight: normal;
    }
    :local(.discount) {
      background-color: var(--mainColor);
      border-radius: 5px;
      font-size: 18px;
      padding: 5px 10px;
      margin-left: 10px;
      color: #fff;
      font-weight: bold;
      line-height: 20px;
      margin-top: 5px;
      vertical-align: middle;
      display: inline-block;
    }
    :local(.price) {
      font-size: 32px;
      margin: 0 var(--spacing_half) var(--spacing);
    }
  }
  @media (max-width: 640px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      "left"
      "right";
    grid-template-rows: auto auto;

    :local(.image) {
      height: auto;
      width: 100%;
      text-align: center;
      img {
        width: 100px;
        margin: 0 auto;
      }
    }
    :local(.info) {
      margin: var(--spacing) var(--spacing);
      line-height: 150%;
      :local(.title) {
        font-size: 16px;
        margin-bottom: var(--spacing);
      }
      :local(.details) {
        font-size: 14px;
        margin: 0;
        ul {
          padding-left: 20px;
        }
        li {
          padding-left: 0;
          margin-left: 0;
        }
      }
      :local(.original_price) {
        text-align: center;
      }
      :local(.price) {
        text-align: center;
        font-size: 32px;
        margin: var(--spacing_half) var(--spacing) var(--spacing2x);
      }
      :local(.cta) {
        text-align: center;
        a {
          display: inline-block;
        }
      }
    }
  }
}

:local(.contactButton) {
  background: var(--buttonColor);
  color: #fff;
  display: inline-block;
  margin: var(--spacing) auto;
  text-align: center;
  border-radius: 200px;
  padding: var(--spacing) var(--spacing2x);
  font-size: 24px;
  font-weight: 500;
  vertical-align: middle;
  line-height: 32px;

  svg {
    margin-right: 10px;
  }

  @media (max-width: 640px) {
    margin: var(--spacing_half);
    font-size: 18px;
  }
}
