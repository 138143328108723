:local(.header) {
    background: #fff;
    :local(.inner) {
        padding: var(--spacing_half);
        max-width: 1000px;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 1fr;
    }
    
    :local(.logo) {
        text-align: center;
        margin:auto;
        @media(max-width:640px) {
            height: 32px;
        }
    }
}

:local(.body) {
    :local(.inner) {
        max-width: 1000px;
        margin: 0 auto;
    }
}

:local(.footer) {
    background: #fff;
    padding: var(--spacing2x);

    :local(.inner) {
        max-width: 1000px;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 1fr auto;
    }


    @media(max-width:640px) {
        padding: var(--spacing);
    }
}