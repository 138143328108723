:root {
  --mainColor: #0cd870;
  --mainColorLight: #d8f4e6;
  --buttonColor: #0cd870;
  --mainColorTr0: #963D9708;
  --mainColorTr: #963D9716;
  --mainColorTr2: #963D9732;
  --gray1: #333;
  --gray2: #444;
  --gray3: #777;
  --gray4: #999;
  --gray5: #ccc;
  --gray6: #eee;
  --gray7: #f5f5f5;
  --maxWidth: 1300px;

  --small2x: .7rem;
  --small: .8rem;
  --normalsmall: .9rem;
  --normal: 1rem;
  --big: 1.2rem;
  --big2x: 1.4rem;
  --big4x: 1.6rem;
  --big8x: 2rem;
  --big16x: 3rem;

  --spacing: 20px;
  --spacing2x: 40px;
  --spacing4x: 80px;
  --spacing8x: 120px;
  --spacing_half: 10px;
  --spacing_quarter: 5px;
  --radius: 3px;
  --strong: bold;
}

html {
  font-size: 16px;
}

body {
  line-height: 1.5715;
  margin: 0;
  font-family: 'Noto Sans JP', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  font-weight: 300;
}



a {
  color: var(--mainColor);
  text-decoration: underline;
}

a:hover {
  color: var(--mainColor);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}